import React from "react";
import "./style.scss";

const Ribbon = () => {
  return (
    <>
      <div id="project-synopsis-id"></div>
      <div className="d-md-flex justify-content-center ribbon">
        <a className="ribbon-item" href="#project-synopsis-id">
          Project Synopsis
        </a>
        <a className="ribbon-item" href="#location">
          Location
        </a>
        <a className="ribbon-item" href="#project-spec">
          Project Specifications
        </a>
        <a className="ribbon-item" href="#floor-plan-id">
          Floor Plan
        </a>
        <a className="ribbon-item" href="#gallery-id">
          Gallery
        </a>
        <a className="ribbon-item" href="#project-status-id">
          Project Status
        </a>
        <a className="ribbon-item" href="#reports-id">
          Monthly Reports
        </a>
      </div>
    </>
  );
};

export default Ribbon;
