import { Cloudinary } from "@cloudinary/url-gen";
import { Resize } from "@cloudinary/url-gen/actions/resize";

export const currencyFormat = (value, type = "USD") => {
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: type,
  });
  return formatter.format(parseFloat(value ? value : 0));
};

export const crispStyle = {
  container: (prop) => ({
    ...prop,
  }),
  control: (prop, { isFocused, isDisabled }) => ({
    ...prop,
    alignItems: "flex-start",
    borderRadius: "2px",
    minHeight: "30px",
    borderColor: isFocused ? "#3a3a3a" : "#ced4da",
    boxShadow: isFocused ? "0 0 0 0.2rem #44444440" : "0 !important",
    fontColor: "#675d5a",
    "&:hover": {
      // borderColor: "#ced4da",
    },
    "&:focus": {
      // borderColor: "#222",
    },
  }),
  input: (prop) => ({
    ...prop,
    margin: 0,
    padding: "0",
    fontSize: ".75rem",
  }),
  singleValue: (prop) => ({
    ...prop,
    margin: 0,
    padding: 0,
    fontSize: ".75rem",
    color: "#495057",
  }),
  indicatorsContainer: (prop) => ({
    ...prop,
    margin: 0,
    padding: 0,
  }),

  dropdownIndicator: (prop) => ({
    ...prop,
  }),
  indicatorSeparator: (prop) => ({
    ...prop,
    backgroundColor: "#dee2e6",
  }),
  clearIndicator: (prop) => ({
    ...prop,
  }),
  valueContainer: (prop, { isMulti }) => ({
    ...prop,
    minHeight: "30px",
    padding: isMulti ? "0 10px" : "9.2px",
  }),
  option: (prop, { isSelected, isFocused }) => ({
    ...prop,
    padding: "12px",
    fontSize: ".75rem",
    backgroundColor: isSelected ? "#343a40" : isFocused && "#ced4da",
    "&:hover": {
      backgroundColor: isSelected ? "#343a40" : "#ced4da",
    },
  }),
  noOptionsMessage: (prop) => ({
    ...prop,
    fontSize: ".75rem",
  }),
  placeholder: (prop) => ({
    ...prop,
    fontSize: ".75rem",
  }),
  menu: (prop) => ({
    ...prop,
    borderRadius: "3px",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999, top: base.top - 5 }),
};

export const numberFormatter = (num, digits = 1, currency = "USD") => {
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  });
  const symb = formatter.format(1).replace("1.00", "");

  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: " Million" },
    { value: 1e9, symbol: " Billion" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return (
    symb +
    (item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0")
  );
};

const cld = new Cloudinary({
  cloud: {
    cloudName: "cloudpencils-media",
  },
});

export const getImageUrl = (input) => {
  const myImage = cld.image(input.public_id);
  // myImage.format("auto").quality("50");
  myImage.resize(Resize.scale().width(1000).height(1000));
  const myUrl = myImage.toURL();
  return input.url;
};

export const getOgImageUrl = (input) => {
  const myImage = cld.image(input.public_id);
  myImage.format("auto").quality("100");
  myImage.resize(Resize.scale());
  const myUrl = myImage.toURL();
  return myUrl;
};
