import React, { useEffect, useState } from "react";

import { getMultiDocument } from "../../client/methods";
import "./style.scss";
import AppLightBox from "../CustomModal/index";

const ProjectStatus = ({ data = [], gallery = false }) => {
  const [list, setList] = useState(data);

  return (
    <section id={gallery ? "Gallery_wrapper" : "Project_Status"}>
      <div className="container">
        <div className="section-head">
          {gallery ? "Gallery" : "Project Status"}
        </div>
        <div className="section-sub">
          {list.map((o, i) => (
            <div
              key={`sub-btn-${i}`}
              className={`sub-item ${o.active ? "active" : ""}`}
              onClick={() => {
                var xx = [];
                for (var x = 0; x < list.length; x++) {
                  if (i === x) {
                    xx.push({ ...list[x], active: true });
                  } else {
                    xx.push({ ...list[x], active: false });
                  }
                }

                setList(xx);
              }}
            >
              {o.title}
            </div>
          ))}
        </div>

        <div className="section-desc">
          {(() => {
            const images = list?.find((o) => o.active === true)?.images;

            return images && <Light docIds={images.slice(0, 10)} />;
          })()}
        </div>

        {gallery ? (
          <div id="project-status-id"></div>
        ) : (
          <div id="reports-id"></div>
        )}
      </div>
    </section>
  );
};

const Light = ({ docIds }) => {
  const [list, setList] = useState([]);

  const getListDoc = async () => {
    try {
      const result = await getMultiDocument(docIds);
      setList(result.data.response);
    } catch (error) {
      console.log("🚀 ~ file: index.js:159 ~ getListDoc ~ error", error);
    }
  };

  useEffect(() => {
    getListDoc();
  }, [docIds]);

  return (
    <>
      <AppLightBox
        data={list.map((cc) => ({
          type: cc.document.type,
          link: cc.document.url,
          text: cc.document.name,
        }))}
      />
    </>
  );
};

export default ProjectStatus;
