import React from "react";
import "./style.scss";
import Highlight from "../../images/spurs_2.jpg";

const ProjectDetails = ({ content }) => {
  return (
    <>
      {/* <section id="project-synopsis">
        <div className="container">
          <div className="section-head">Project Synopsis</div>
          <div className="section-desc" style={{ whiteSpace: "pre-wrap" }}>
            {content}
          </div>
        </div>
      </section> */}
      <section id="project-synopsis">
        <div className="container design_2">
          <div className="row">
            <div className="col-md-6 ">
              <div className="section-head">Project Synopsis</div>
              <div className="section-desc" style={{ whiteSpace: "pre-wrap" }}>
                
                {content}
                <p className="mt-4"> <a className="btn btn-primary">Talk with Expert</a> </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="img_sec">
                <img src={Highlight} alt="property" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProjectDetails;
