import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Logo from "../../images/logo.png";
import Author from "../../images/profile.jpg";
import {
  FaBell,
  FaCog,
  FaHeart,
  FaSignOutAlt,
  FaUser,
  FaUserCog,
  FaWallet,
} from "react-icons/fa";
import "./style.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Header({ page }) {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  return (
    <>
      <div className="header_wrapper">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-5">
              <div className="logo">
                <img
                  src={Logo}
                  alt=""
                  width={130}
                  onClick={() => navigate("/")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Header;
