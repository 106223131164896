import * as yup from "yup";

export const registerSchema = yup.object().shape({
  first_name: yup.string().trim().lowercase().required("First Name Required"),
  last_name: yup.string().trim().lowercase().required("Last Name Required"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email Required"),
  password: yup
    .string()
    .trim()
    .lowercase()
    .min(6)
    .required("Password Required"),
  c_password: yup
    .string()
    .lowercase()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password Required"),
});

export const interestSchema = yup.object().shape({
  name: yup.string().trim().required("Name required"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email required"),
  offering_amount: yup.string().required("Offering amount required"),
});

export const accountSchema = yup.object().shape({
  legal_investing_account_name: yup
    .string()
    .trim()
    .lowercase()
    .required("Legal Investing Name Required"),
  ssn: yup.string().trim().lowercase().required("SSN Required"),
  address: yup.string().trim().lowercase().required("Address Required"),
  state: yup.string().trim().lowercase().required("State Required"),
  city: yup.string().trim().lowercase().required("City Required"),
  zip: yup.string().trim().lowercase().required("Zipcode Required"),
  country: yup.string().trim().lowercase().required("Country Required"),
});

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email Required"),
  password: yup
    .string()
    .trim()
    .lowercase()
    .min(6)
    .required("Password Required"),
});

export const investorSchema = yup.object().shape({
  first_name: yup
    .string()
    .trim()
    .lowercase()
    .min(1, "Name must be at least 5 Characters long")
    .required("First Name Required"),
  last_name: yup
    .string()
    .trim()
    .lowercase()
    .min(1, "Name must be at least 5 Characters long")
    .required("Last Name Required"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email Required"),
});

export const ChangePasswordSchema = yup.object().shape({
  old_password: yup
    .string()
    .min(6, "Enter a correct password")
    .required("Old Password required"),

  new_password: yup
    .string()
    .min(6, "Password must be 6 characters long")
    .required("New Password Required"),

  confirm_password: yup
    .string()
    .oneOf([yup.ref("new_password"), null], "Password must match")
    .required("Required"),
});
