import toast from "react-hot-toast";

const responseMsg = [
  {
    code: "OK001",
    message:
      "Please verify your email, we have sent an OTP to your email address",
    type: "success",
  },
  {
    code: "OK002",
    message: "OTP sent to your email address for reset the password",
    type: "success",
  },
  {
    code: "OK003",
    message: "Logged in successfully",
    type: "success",
  },
  {
    code: "OK004",
    message: "Registration successful",
    type: "success",
  },
  {
    code: "OK005",
    message: "OTP validation successful",
    type: "success",
  },
  {
    code: "OK006",
    message: "Reset password successful, you can proceed to login",
    type: "success",
  },
  {
    code: "OK007",
    message: "OTP resent to your registered email address",
    type: "success",
  },
  {
    code: "OK008",
    message: "Leads has been created",
    type: "success",
  },
  {
    code: "OK009",
    message: "Leads has been Updated",
    type: "success",
  },
  {
    code: "OK010",
    message: "Leads has been deleted",
    type: "success",
  },
  {
    code: "OK011",
    message: "Listing has been Created",
    type: "success",
  },
  {
    code: "OK012",
    message: "Listing has been updated",
    type: "success",
  },
  {
    code: "OK013",
    message: "Listing has been deleted",
    type: "success",
  },
  {
    code: "OK014",
    message: "Token has been Created",
    type: "success",
  },
  {
    code: "OK015",
    message: "Token has been Updated",
    type: "success",
  },
  {
    code: "OK016",
    message: "Token has been deleted",
    type: "success",
  },
  {
    code: "OK017",
    message: "Transaction has been Created",
    type: "success",
  },
  {
    code: "OK018",
    message: "Transaction has been Updated",
    type: "success",
  },
  {
    code: "OK019",
    message: "Transaction has been deleted",
    type: "success",
  },
  {
    code: "OK020",
    message: "User has been Created",
    type: "success",
  },
  {
    code: "OK021",
    message: "User has been Updated",
    type: "success",
  },
  {
    code: "OK022",
    message: "User has been deleted",
    type: "success",
  },
  {
    code: "OK023",
    message: "User Password has been Reset",
    type: "success",
  },
  {
    code: "OK024",
    message: "Password has been Change Successfully",
    type: "success",
  },
  {
    code: "OK025",
    message:
      "New Password has been Created, Please login with the new password",
    type: "success",
  },
  {
    code: "OK026",
    message: "Roles has been Created",
    type: "success",
  },
  {
    code: "OK027",
    message: "Roles has been Updated",
    type: "success",
  },
  {
    code: "OK028",
    message: "Roles has been deleted",
    type: "success",
  },
  {
    code: "OK029",
    message: "Registration Successful",
    type: "success",
  },
  {
    code: "OK030",
    message: "Profile has been Updated Successfully",
    type: "success",
  },
  {
    code: "OK031",
    message: "Investor has been deleted",
    type: "success",
  },
  {
    code: "OK032",
    message: "Token has been Requested",
    type: "successs",
  },

  {
    code: "OK033",
    message: "Comment created successfully",
    type: "success",
  },

  {
    code: "OK034",
    message: "Comment updated successfully",
    type: "success",
  },

  {
    code: "OK035",
    message: "Deleted successfully",
    type: "success",
  },

  {
    code: "OK036",
    message: "Transaction Updated Successfully",
    type: "success",
  },
  {
    code: "OK037",
    message: "Image Removed Successfully",
    type: "success",
  },
  {
    code: "OK038",
    message: "Invite Successfully",
    type: "success",
  },
  {
    code: "OK039",
    message: "Document Uploaded Successfully",
    type: "success",
  },
  {
    code: "OK040",
    message: "Document Deleted Successfully",
    type: "success",
  },
  {
    code: "OK041",
    message: "Investment Created Successfully",
    type: "success",
  },
  {
    code: "OK042",
    message: "Investment Updated Successfully",
    type: "success",
  },
  {
    code: "OK043",
    message: "Investment Deleted Successfully",
    type: "success",
  },
  {
    code: "OK044",
    message: "Property Created Successfully",
    type: "success",
  },
  {
    code: "OK045",
    message: "Property Updated Successfully",
    type: "success",
  },
  {
    code: "OK046",
    message: "Property Deleted Successfully",
    type: "success",
  },
  {
    code: "OK050",
    message: "Distribution Created Successfully",
    type: "success",
  },
  {
    code: "OK048",
    message: "Distribution Updated Successfully",
    type: "success",
  },
  {
    code: "OK049",
    message: "Distribution Deleted Successfully",
    type: "success",
  },
  {
    code: "OK047",
    message: "Email Sent Successfully",
  },
  {
    code: "ER999",
    message: "Something went wrong",
    type: "error",
  },
  {
    code: "ER001",
    message:
      "Something went wrong while sending OTP, please try again after sometime",
    type: "error",
  },
  {
    code: "ER002",
    message: "User not active, please contact support for futher assistance",
    type: "error",
  },
  {
    code: "ER003",
    message: "Incorrect credentials",
    type: "error",
  },
  {
    code: "ER004",
    message: "User not verified, please login and verify your email",
    type: "error",
  },
  {
    code: "ER005",
    message: "Email Id already exist, please signin to continue",
    type: "error",
  },
  {
    code: "ER006",
    message: "Phone number already exist",
    type: "error",
  },
  {
    code: "ER007",
    message: "Reset Password failed, please try again",
    type: "error",
  },
  {
    code: "ER008",
    message: "Invalid OTP, please check your OTP once again",
    type: "error",
  },
  {
    code: "ER009",
    message: "OTP Expired, please try again",
    type: "error",
  },
  {
    code: "ER010",
    message: "User not found",
    type: "error",
  },
  {
    code: "ER011",
    message: "Please try resend OTP after 10 min",
    type: "error",
  },
  {
    code: "ER012",
    message: "Email does not have any account",
    type: "error",
  },
  {
    code: "ER013",
    message: "Invalid Credentials",
    type: "error",
  },
  {
    code: "ER014",
    message: "User IS not active please contact admin",
    type: "error",
  },
  {
    code: "ER015",
    message: "Old Password is wrong",
    type: "error",
  },
  {
    code: "ER016",
    message: "Page not Found",
    type: "error",
  },
  {
    code: "ER017",
    message: "UnAuthorized Response",
    type: "error",
  },
  {
    code: "ER018",
    message: "Contact Already Added",
    type: "error",
  },
  {
    code: "ER019",
    message: "Property Already Added",
    type: "error",
  },
];

export const responseToastMsg = (err_code) => {
  const info = responseMsg.find((o) => o.code === err_code);
  toast[info.type](info.message);
};
