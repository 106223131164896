import React, { useEffect, useState } from "react";
import { getMultiDocument } from "../../client/methods";
import { FaMapMarkerAlt} from "react-icons/fa";

import "./style.scss";

const Banner = ({ docIds }) => {
  const [list, setList] = useState([]);

  const getListDoc = async () => {
    try {
      const result = await getMultiDocument(docIds);
      setList(result.data.response);
    } catch (error) {
      console.log("🚀 ~ file: index.js:159 ~ getListDoc ~ error", error);
    }
  };

  useEffect(() => {
    getListDoc();
  }, [docIds]);

  return (
    <section className="top-banner">
        <div className="container">
          <div className="content">
            <h1>Spurs Lane Multifamily</h1>
            <h4><FaMapMarkerAlt/> 300 Spurs Lane, San Antonio, TX, 78240</h4>
          </div>
         </div>
      {list.map((o) => (
        <img src={o.document.url} />
      ))}
    </section>
  );
};

export default Banner;
