import React, { useState, useEffect } from "react";

import { getMultiDocument } from "../../client/methods";

import "./style.scss";
import AppLightBox from "../CustomModal/index";

const FloorPlan = ({ docIds = [], title = "Floor Plan" }) => {
  const [list, setList] = useState([]);

  const getListDoc = async () => {
    try {
      const result = await getMultiDocument(docIds.slice(0, 10));
      setList(result.data.response);
    } catch (error) {
      console.log("🚀 ~ file: index.js:159 ~ getListDoc ~ error", error);
    }
  };

  useEffect(() => {
    getListDoc();
  }, [docIds]);

  return (
    <>
      <section id="floor-plan">
        <div className="row">
          <div className="container">
            <div className="section-head">{title}</div>
            <div className="section-desc">
              <AppLightBox
                data={list.map((cc) => ({
                  type: cc.document.type,
                  link: cc.document.url,
                  text: cc.document.name,
                }))}
              />
            </div>
          </div>
          <div id="gallery-id"></div>
        </div>
      </section>
    </>
  );
};

export default FloorPlan;
