import React, { useEffect, useState } from "react";
import Header from "./header/index";
import Footer from "./footer/index";
import Banner from "../components/banner";
import Ribbon from "../components/ribbon/index";
import ProjectDetails from "../components/project-details/index";
import Location from "../components/location/index";
import ProjectSpec from "../components/project-spec";
import FloorPlan from "../components/floor-plan/index";

import ProjectStatus from "../components/project-status/index";
import People from "../components/people";
import Disclaimers from "../components/disclaimers";
import { getWebsiteByPageId } from "../client/methods";
import { useParams } from "react-router-dom";
import ProjectSpecs from "../components/project-specs/index";

import MonthlyReports from "../components/monthly-reports/index";

const Home = () => {
  const [website, setWebsite] = useState();
  const { page_id } = useParams();

  const handleGetData = async (input) => {
    try {
      const result = await getWebsiteByPageId(input);

      setWebsite(result.data.response);
    } catch (error) {
      console.log("🚀 ~ file: home.js:24 ~ handleGetData ~ error", error);
    }
  };
  useEffect(() => {
    page_id && handleGetData(page_id);
  }, []);

  return website ? (
    <>
      <Header />
      <Banner docIds={website?.banner_image} />
      <Ribbon />
      <ProjectDetails content={website?.project_details} />
      <Location
        address={`${website?.address}, ${website?.city}, ${website?.state}, ${website?.zip_code}`}
        content={website?.location_details}
      />
      <ProjectSpecs />

      <FloorPlan docIds={website?.floor_plans} />

      <ProjectStatus data={website?.gallery} gallery />
      <ProjectStatus data={website?.project_status} />
      <MonthlyReports />
      <People />

      {/* <Disclaimers /> */}
      <Footer />
    </>
  ) : null;
};

export default Home;
