import React from "react";
import { FiArrowRight } from "react-icons/fi";

const ProjectSpecs = () => {
  return (
    <>
      <section id="specs">
        <div className="row">
          <div className="container">
            <div className="section-head">Project Specifications</div>
            <div className="section-desc">
              <a
                target={"_blank"}
                href="https://alamo-project-files.s3.ap-south-1.amazonaws.com/Spurs+Lane+Apts+bid+set+2022.01.04_compressed+(1)+(1).pdf"
              >
                View Specs <FiArrowRight />
              </a>
            </div>
            <div id="floor-plan-id"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProjectSpecs;
