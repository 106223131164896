import { createAsyncThunk } from "@reduxjs/toolkit";

import { login, logout } from "../reducers/user.reducer";
import { loginApi } from "../../client/methods";
import { responseToastMsg } from "../../utils/response-message";
import { removeCookies, setCookies } from "../../utils/cookies";

export const userLoginThunk = createAsyncThunk(
  "/sample",
  async (input, thunkAPI) => {
    try {
      // const { data, callback } = input;
      let result = await loginApi(input);

      if (result.status === 200) {
        responseToastMsg(result.data.response_code);

        setCookies(result.data.response.token);

        thunkAPI.dispatch(login(result.data.response));
      }
    } catch (err) {
      responseToastMsg(err.data.error_code);

      thunkAPI.dispatch(logout());
    }
  }
);

export const userLogoutThunk = createAsyncThunk(
  "/reset",
  (request, thunkAPI) => {
    removeCookies();
    thunkAPI.dispatch(logout());
  }
);
