import React from "react";

import location from "../../images/location.png";
import "./style.scss";

const Location = ({ address, content }) => {
  return (
    <section id="location">
      <div className="iframe_wrapper">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3471.644106894751!2d-98.59416014972523!3d29.526731181985678!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x865c6742468ff577%3A0xc846600c7dceada9!2sSpurs%20Ln%2C%20San%20Antonio%2C%20TX%2078240%2C%20USA!5e0!3m2!1sen!2sin!4v1671278179775!5m2!1sen!2sin"></iframe>
      </div>
      <div className="container">
        <div className="section-desc">
          <div className="section-head">Location</div>
          <p>{address}</p>
          <p id="project-spec">{content}</p>
        </div>
      </div>
    </section>
  );
};

export default Location;
