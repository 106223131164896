import React from "react";
import "./style.scss";

function Footer() {
  return (
    <>
      <div className="footer_wrapper">
        <div className="container">
          <div className="row footer_top"></div>
          <div className="row footer_bootom">
            <div className="col-md-12 text-center">
              <div className="copyright">
                <p>Copyright © 2022 Alamo Equity. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
