import React from "react";
import Logan from "../../images/logan.jpg";
import "./style.scss";

const People = () => {
  return (
    <>
      <section id="dev_team">
        <div className="container">
          <div className="section-head">Development Team</div>
          <div className="sp_sec">
            <div className="single_sp">
              <div className="row flex-md-row-reverse align-items-center">
                <div className="col-md-3">
                  <div className="sp_img">
                    <img
                      src={
                        "https://res.cloudinary.com/cloudpencils-media/image/upload/v1670742120/website/Larry_Waters_whitebg_y56t2r.jpg"
                      }
                      alt="Sponsor"
                    />
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="sp_cnt">
                    <h3>
                      Larry Waters <span>President - Multifamily</span>
                    </h3>
                    <p>
                      40+ years of multifamily construction experience Involved
                      in the construction of over 100 apartment projects and
                      40,000 units in 18 States Developed apartments in multiple
                      States: Texas, Ohio, Indiana, North and South Carolina,
                      Virginia, Kansas, Oklahoma, Maryland, Delaware, Utah, New
                      Jersey, Pennsylvania, Colorado, Georgia, and Florida.
                      Built and delivered over $6 billion worth of multifamily
                      projects over 40 years Serves as President of Alamo
                      Multifamily Group (AMG) based out of Houston, Texas
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="single_sp">
              <div className="row flex-md-row-reverse align-items-center">
                <div className="col-md-3">
                  <div className="sp_img">
                    <img
                      src={
                        "https://res.cloudinary.com/cloudpencils-media/image/upload/v1670742120/website/Kenneth-Steidley-_whitebg_es0gsc.jpg"
                      }
                      alt="Sponsor"
                    />
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="sp_cnt">
                    <h3>
                      Kenneth Steidley{" "}
                      <span>VP Development, Land Acquisition</span>
                    </h3>
                    <p>
                      Ken Steidley serves as VP of AMG and president of The
                      Afton Co., which was founded in 1975, specializing in
                      multifamily finance and land transactions in Houston,
                      Texas. With over 30 years of commercial real estate, Mr.
                      Steidley has sold over 30,000 apartment units valued over
                      $1.5 billion. Recognized as a leading expert in the
                      commercial real estate field, Mr. Steidley has represented
                      some of the largest multifamily real estate companies in
                      the country, and has been responsible for several
                      multifamily key transactions, including the sale, for one
                      client of more than 4,000 apartment units. Mr. Steidley is
                      an active part of the commercial real estate community,
                      being involved in both the Houston Apartment Association
                      and the Texas Apartment Association. Community involvement
                      includes membership in the Sugarcreek Country Club and St.
                      Lawrence Catholic Church.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="cont_team">
        <div className="container">
          <div className="section-head">Project General Contractor Team</div>
          <div className="sp_sec">
            <div className="single_sp">
              <div className="row flex-md-row-reverse align-items-center">
                <div className="col-md-3">
                  <div className="sp_img">
                    <img
                      src={
                        "https://res.cloudinary.com/cloudpencils-media/image/upload/v1670742372/website/banner_1_u5lykm.webp"
                      }
                      alt="Sponsor"
                    />
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="sp_cnt">
                    <h3>
                      Don Milbauer <span>President</span>
                    </h3>
                    <p>
                      Founding Domain Builders in Houston, Texas in 2016, Don
                      seized the opportunity to offer the construction industry
                      a fresh perspective. Despite a construction-dense region,
                      Domain Builders challenges current standards by offering
                      consciously executed plans and projects thanks to a
                      breadth of knowledge from a loyal and vastly experienced
                      team. In a short time, Domain Builders has made swift
                      progress in bringing homegrown values of commitment,
                      ingenuity and excellence back to the forefront of Houston
                      construction.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="single_sp">
              <div className="row flex-md-row-reverse align-items-center">
                <div className="col-md-3">
                  <div className="sp_img">
                    <img
                      src={
                        "https://res.cloudinary.com/cloudpencils-media/image/upload/v1670742372/website/banner_girurq.webp"
                      }
                      alt="Sponsor"
                    />
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="sp_cnt">
                    <h3>
                      Matt Long <span>VP of Construction</span>
                    </h3>
                    <p>
                      Vice President of Construction at Domain Builders, Matt
                      Long continues to enhance his track record in construction
                      management. Matt oversees the estimating, planning, and
                      execution for all Domain Builders projects. Matt graduated
                      from Texas A&M University with a Bachelor of Science
                      degree in Construction Science.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="single_sp">
              <div className="row flex-md-row-reverse align-items-center">
                <div className="col-md-3">
                  <div className="sp_img">
                    <img
                      src={
                        "https://res.cloudinary.com/cloudpencils-media/image/upload/v1670742869/website/Screenshot_2022-12-11_at_12.43.39_PM_jbfnia.png"
                      }
                      alt="Sponsor"
                    />
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="sp_cnt">
                    <h3>
                      Sherri Swope <span>Project Manager</span>
                    </h3>
                    <p>
                      Vice President of Construction at Domain Builders, Matt
                      Long continues to enhance his track record in construction
                      management. Matt oversees the estimating, planning, and
                      execution for all Domain Builders projects. Matt graduated
                      from Texas A&M University with a Bachelor of Science
                      degree in Construction Science.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default People;
