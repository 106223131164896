import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { getMultiDocument } from "../../client/methods";
import "./style.scss";
import AppLightBox from "../CustomModal/index";
import UAParser from "ua-parser-js";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaFilePdf } from "react-icons/fa";

// const MonthlyReports = () => {
//   return (
//     <>
//       <section id="reports">
//         <div className="row">
//           <div className="container">
//             <div className="section-head">Monthly Reports</div>
//             <div className="section-desc">
//               <a
//                 target={"_blank"}
//                 href="https://res.cloudinary.com/cloudpencils-media/image/upload/v1676116505/funding/production/01._Spurs_Lane_-_Detailed_Project_Cost_Report_-_02.03.2023_wgxoyp.pdf"
//               >
//                 Detailed Project Cost Report <FiArrowRight />
//               </a>
//             </div>
//             <div className="section-desc mt-4">
//               <a
//                 target={"_blank"}
//                 href="https://res.cloudinary.com/cloudpencils-media/image/upload/v1676116505/funding/production/02._Cost_Control_Estimate_01.23.23_gxrhdx.pdf"
//               >
//                 Cost Control Estimate <FiArrowRight />
//               </a>
//             </div>
//             <div className="section-desc mt-4">
//               <a
//                 target={"_blank"}
//                 href="https://res.cloudinary.com/cloudpencils-media/image/upload/v1676116506/funding/production/03._Spurs_Lane_-_Schedule_-_01.23.23_jqhkja.pdf"
//               >
//                 Spurs Lane Schedule <FiArrowRight />
//               </a>
//             </div>
//             <div className="section-desc mt-4">
//               <a
//                 target={"_blank"}
//                 href="https://res.cloudinary.com/cloudpencils-media/image/upload/v1676116505/funding/production/04._Spurs_-_SOV_Contingency_Reallocations_01.23.23_q3qmza.pdf"
//               >
//                 SOV Contingency Reallocations <FiArrowRight />
//               </a>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default MonthlyReports;

const MonthlyReports = ({}) => {
  const data = [
    {
      active: true,
      title: "Aug 2022",
      images: [
        {
          title: "Control Estimate - Aug 2022",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1676130632/funding/production/Cost_Control_Estimate_08.22.22_rpr7gp.pdf",
        },
        {
          title: "Schedule Report - Aug 2022",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1676130531/funding/production/Spurs_Lane_Schedule_-_08.01.22_slszei.pdf",
        },
      ],
    },
    {
      active: false,
      title: "Sept 2022",
      images: [
        {
          title: "Cost Report - Sept 2022",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1676130717/funding/production/01_Spurs_Lane_C2C_-_09.06.22_dguiqh.pdf",
        },
        {
          title: "Control Estimate - Sept 2022",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1676130631/funding/production/Cost_Control_Estimate_09.23.22_kfxeee.pdf",
        },
        {
          title: "Schedule Report - Sept 2022",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1676130525/funding/production/Spurs_Lane_-_Schedule_-_09.09.22_ow4bqn.pdf",
        },
      ],
    },
    {
      active: false,
      title: "Oct 2022",
      images: [
        {
          title: "Cost Report - Oct 2022",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1676130717/funding/production/02_Spurs_Lane_C2C_-_10.02.22_ysqkld.pdf",
        },
        {
          title: "Control Estimate - Oct 2022",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1676130631/funding/production/Cost_Control_Estimate_10.17.22_atx7vq.pdf",
        },
        {
          title: "Schedule Report - Oct 2022",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1676130527/funding/production/Spurs_Lane_-_Schedule_-_10.17.22_esrjbv.pdf",
        },
        {
          title: "Contingency Report - Oct 2022",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1676130421/funding/production/Spurs_-_SOV_Contingency_Reallocations_10.31.22_wmklbn.pdf",
        },
      ],
    },
    {
      active: false,
      title: "Nov 2022",
      images: [
        {
          title: "Cost Report - Nov 2022",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1676130717/funding/production/03_Spurs_Lane_C2C_-_11.02.2022_faz5wd.pdf",
        },
        {
          title: "Control Estimate - Nov 2022",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1676130631/funding/production/Cost_Control_Estimate_11.22.22_l6rwt6.pdf",
        },
        {
          title: "Schedule Report - Nov 2022",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1676130532/funding/production/Spurs_Lane_-_Schedule_-_11.03.22_j04z7r.pdf",
        },
        {
          title: "Contingency Report - Nov 2022",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1676130423/funding/production/Spurs_-_SOV_Contingency_Reallocations_11.22.22_hmgutg.pdf",
        },
      ],
    },
    {
      active: false,
      title: "Dec 2022",
      images: [
        {
          title: "Cost Report - Dec 2022",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1676130717/funding/production/04_Spurs_Lane_C2C_-_12.08.2022_ipwnkw.pdf",
        },
        {
          title: "Control Estimate - Dec 2022",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1676130632/funding/production/Cost_Control_Estimate_12.19.22_t7l4fy.pdf",
        },
        {
          title: "Schedule Report - Dec 2022",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1676130534/funding/production/Spurs_Lane_-_Schedule_-_12.09.22_b20fub.pdf",
        },
        {
          title: "Contingency Report - Dec 2022",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1676130422/funding/production/Spurs_-_SOV_Contingency_Reallocations_12.19.22_xuungw.pdf",
        },
      ],
    },
    {
      active: false,
      title: "Jan 2023",
      images: [
        {
          title: "Cost Report - Jan 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1676130224/funding/production/05_Spurs_Lane_C2C_-_01.16.2023_uqivnu.pdf",
        },
        {
          title: "Control Estimate - Jan 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1676130296/funding/production/Cost_Control_Estimate_01.03.23_sxeilh.pdf",
        },
        {
          title: "Schedule Report - Jan 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1676130322/funding/production/Spurs_Lane_-_Schedule_-_01.03.23_hdot6c.pdf",
        },
        {
          title: "Contingency Report - Jan 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1676130352/funding/production/Spurs_-_SOV_Contingency_Reallocations_01.23.23_zlirvd.pdf",
        },
      ],
    },
    {
      active: false,
      title: "Feb 2023",
      images: [
        {
          title: "Cost Report - Feb 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1678482905/funding/production/06_Spurs_Lane_C2C_-_02.03.2023_kwql8y.pdf",
        },
        {
          title: "Control Estimate - Feb 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1680094168/funding/production/02._Cost_Control_Estimate_02.21.23_ie0t2i.pdf",
        },
        {
          title: "Schedule Report - Feb 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1680094173/funding/production/03._Spurs_Lane_-_Schedule_-_02.24.23_wkcfx8.pdf",
        },
        {
          title: "Contingency Report - Feb 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1680094168/funding/production/04._Spurs_-_SOV_Contingency_Reallocations_02.21.23_muspd9.pdf",
        },
      ],
    },
    {
      active: false,
      title: "Mar 2023",
      images: [
        {
          title: "Cost Report - Mar 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1680942977/funding/production/01._Spurs_Lane_-_Detailed_Project_Cost_Report_-_03.09.2023_fcydod.pdf",
        },
        {
          title: "Control Estimate - Mar 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1680942968/funding/production/02._Cost_Control_Estimate_03.17.23_svaubb.pdf",
        },
        {
          title: "Schedule Report - Mar 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1680942969/funding/production/03._Spurs_Lane_-_Schedule_-_03.27.23_vqkic7.pdf",
        },
        {
          title: "Contingency Report - Mar 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1680942968/funding/production/04._Spurs_-_SOV_Contingency_Reallocations_03.17.23_qzymcn.pdf",
        },
      ],
    },
    {
      active: false,
      title: "Apr 2023",
      images: [
        {
          title: "Cost Report - Apr 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1680942968/funding/production/01._Spurs_Lane_-_Detailed_Project_Cost_Report_-_04.06.2023_qdpt7c.pdf",
        },
        {
          title: "Control Estimate - Apr 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1686391759/funding/production/02._Cost_Control_Estimate_03.17.23_cutxm3.pdf",
        },
        {
          title: "Schedule Report - Apr 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1686391760/funding/production/03._Spurs_Lane_-_Schedule_-_03.27.23_ensff8.pdf",
        },
        {
          title: "Contingency Report - Apr 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1686391759/funding/production/04._Spurs_-_SOV_Contingency_Reallocations_03.17.23_qid7ku.pdf",
        },
        {
          title: "Quarterly Project Update - Apr 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1686391768/funding/production/Spurs_Lane_Quarterly_Project_Update_04.03.23_cgtsbr.pdf",
        },
      ],
    },
    {
      active: false,
      title: "May 2023",
      images: [
        {
          title: "Cost Report - May 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1686391832/funding/production/01._Spurs_Lane_-_Cost_to_Complete_C2C_Report_-_05.04.23_cc6mmc.pdf",
        },
        {
          title: "Control Estimate - May 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1686391833/funding/production/02._Spurs_Lane_-_Cost_Control_Estimate_05.19.23_d0l1bg.pdf",
        },
        {
          title: "Schedule Report - May 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1686391837/funding/production/06._Spurs_Lane_-_Schedule_-_05.22.23_qvnybw.pdf",
        },
        {
          title: "Contingency Report - May 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1686391833/funding/production/04._Spurs_Lane_-_Contingency_SOV_Reallocations_05.19.23_jwm09k.pdf",
        },
        {
          title: "Payment Draw - May 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1686391835/funding/production/03._Spurs_Lane_-_Draw_10_-_05.23.23_zmyhcz.pdf",
        },
        {
          title: "Owner Change Order Log - May 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1686391833/funding/production/05._Spurs_Lane_-_Owner_Change_Order_Log_-_05.19.23_eavpqo.pdf",
        },
      ],
    },
    {
      active: false,
      title: "Jun 2023",
      images: [
        {
          title: "Cost Report - Jun 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1686391907/funding/production/01._Spurs_Lane_-_Cost_To_Complete_-_06.09.23_yrbd2a.pdf",
        },
        {
          title: "Control Estimate - Jun 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1686391907/funding/production/02._Spurs_Lane_-_Cost_Control_Estimate_-_05.19.23_pt8v7p.pdf",
        },
        {
          title: "Schedule Report - Jun 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1686391908/funding/production/06._Spurs_Lane_-_Schedule_-_05.22.23_ftag1d.pdf",
        },
        {
          title: "Contingency Report - Jun 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1686391908/funding/production/04._Spurs_Lane_-_SOV_Contingency_Reallocations_05.19.23_mye10q.pdf",
        },
        {
          title: "Payment Draw - Jun 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1686391919/funding/production/03._Spurs_Lane_-_Payment_Draw_10_-_05.20.23_fvdtql.pdf",
        },
        {
          title: "Owner Change Order Log - Jun 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1686391908/funding/production/05._Spurs_Lane_-_Owner_Change_Order_Log_05.19.23_dprrq8.pdf",
        },
      ],
    },
    {
      active: false,
      title: "Jul 2023",
      images: [
        {
          title: "Cost Report - Jul 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1691839513/funding/production/01._Spurs_Lane_-_Cost_To_Complete_-_08.04.2023_nsp1a0.pdf",
        },
        {
          title: "Control Estimate - Jul 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1691839513/funding/production/02._Spurs_Lane_-_Control_Estimate_-_06.22.23_-_FINAL_xobgn1.pdf",
        },
        {
          title: "Schedule Report - Jul 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1691839514/funding/production/04._Spurs_Lane_-_Schedule_-_08.10.23_w5aqdg.pdf",
        },
        {
          title: "Contingency Report - Jul 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1691839514/funding/production/03._Spurs_Lane_-_SOV_Contingency_Report_07.18.23_glnqjm.pdf",
        },
      ],
    },
    {
      active: false,
      title: "Aug 2023",
      images: [
        {
          title: "Cost Report - Aug 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1697272583/funding/production/Spurs_Lane_Cost_Report_C2C_-_09.01.2023_y9oeqm.pdf",
        },
        {
          title: "Owner Change Report - Aug 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1697272684/funding/production/Spurs_Lane_-_Owner_Change_Order_Log_08.18.23_qi0qlt.pdf",
        },
        {
          title: "Schedule Report - Aug 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1697272630/funding/production/Spurs_Lane_-_Schedule_-_09.08.23_lgbdel.pdf",
        },
        {
          title: "Contingency Report - Aug 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1697272636/funding/production/Spurs_Lane_-_SOV_Contingency_Reallocations_08.18.23_hzpqht.pdf",
        },
      ],
    },
    {
      active: false,
      title: "Sep 2023",
      images: [
        {
          title: "Cost Report - Sep 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1697272969/funding/production/2._Spurs_Lane_-_Cost_to_Complete_Report_-_10.05.23_v3ssxl.pdf",
        },
        {
          title: "Schedule Report - Sep 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1697272971/funding/production/3b._Spurs_Lane_-_Detailed_Schedule_-10.06.23_pxwjuz.pdf",
        },
        {
          title: "Contingency Report - Sep 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1697272970/funding/production/5._Spurs_Lane_-SOV_Contingency_Reallocations_-_09.19.23_nahlvj.pdf",
        },
        {
          title: "Quaterly Update Report - Sep 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1697272971/funding/production/1._Spurs_Lane_-_Quarterly_Update_-_10.06.23_fljl6u.pdf",
        },
        {
          title: "Draw 14 Report - Sep 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1697272972/funding/production/6._Spurs_Lane_-_Draw_14_-_Through_09.30.23_uflcej.pdf",
        },
      ],
      finance: [
        {
          title: "Job Cost Journal Report - Sep 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1699652924/funding/production/Spurs_WIP_2023-09_macook.pdf",
        },
        {
          title: "Income Statement Report - Sep 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1699652923/funding/production/Spurs_IS_2023-09_vmzjjy.pdf",
        },
        {
          title: "Balance Sheet Report - Sep 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1699652923/funding/production/Spurs_BS_2023-09_hu0rst.pdf",
        },
      ],
    },
    {
      active: false,
      title: "Oct 2023",
      images: [
        {
          title: "Cost Report - Oct 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1699653510/funding/production/1._Spurs_Lane_-_Cost_to_Complete_Report_-_11.03.23_yzjj4d.pdf",
        },
        {
          title: "Schedule Report - Oct 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1699653512/funding/production/3b._Spurs_Lane_-_Detailed_Schedule_-_10.20.23_dwzlbh.pdf",
        },
        {
          title: "Condensed Schedule Report - Oct 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1699653512/funding/production/3a._Spurs_Lane_-_Condensed_Schedule_-_10.20.23_ifgq5x.pdf",
        },
        {
          title: "Contingency Report - Oct 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1699653408/funding/production/4._Spurs_Lane_-SOV_Contingency_Reallocations_-_10.20.23_o6wuz9.pdf",
        },
        {
          title: "Owner Change Order - Oct 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1699653510/funding/production/2._Spurs_Lane_-_Owner_Change_Order_Log_-_10.20.23_khes7e.pdf",
        },
        {
          title: "Draw 15 Report - Oct 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1699653462/funding/production/5._Spurs_Lane_-_Draw_15_-_Through_10.31.23_qmkcgz.pdf",
        },
      ],
      finance: [
        {
          title: "Job Cost Journal Report - Oct 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1699653259/funding/production/Spurs_WIP_2023-10_xar7ts.pdf",
        },
        {
          title: "Income Statement Report - Oct 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1699653259/funding/production/Spurs_IS_2023-10_vrlimq.pdf",
        },
        {
          title: "Balance Sheet Report - Oct 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1699653259/funding/production/Spurs_BS_2023-10_fvb4mk.pdf",
        },
      ],
    },
    {
      active: false,
      title: "Nov 2023",
      images: [
        {
          title: "Cost Report - Nov 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1702095854/funding/production/1._Spurs_Lane_-_Cost_to_Complete_Report_-_11.03.23_n1g6mh.pdf",
        },
        {
          title: "Schedule Report - Nov 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1702095856/funding/production/3b._Spurs_Lane_-_Detailed_Schedule_-_11.16.23_ciyq44.pdf",
        },
        {
          title: "Condensed Schedule Report - Nov 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1702095855/funding/production/3a._Spurs_Lane_-_Condensed_Schedule_11.16.23_f0kgeo.pdf",
        },
        {
          title: "Contingency Report - Nov 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1702095856/funding/production/4._Spurs_-_SOV_Contingency_Reallocations_11.15.23_du5gcg.pdf",
        },
        {
          title: "Owner Change Order - Nov 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1702095855/funding/production/5._Spurs_Lane_-_Owner_Change_Order_Log_11.15.23_jm20fj.pdf",
        },
        {
          title: "Draw 16 Report - Nov 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1702095856/funding/production/6._Spurs_Lane_-_Draw_16_-_Through_11.30.23_swo0sk.pdf",
        },
      ],
      finance: [
        {
          title: "Job Cost Journal Report - Nov 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1705140025/funding/production/Spurs_WIP_2023-10_l7xg4u.pdf",
        },
        {
          title: "Income Statement Report - Nov 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1705140230/funding/production/Spurs_IS_2023-10_vnwpg3.pdf",
        },
        {
          title: "Balance Sheet Report - Nov 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1705140291/funding/production/Spurs_BS_2023-10_mja1vy.pdf",
        },
      ],
    },
    {
      active: false,
      title: "Dec 2023",
      images: [
        {
          title: "Cost Report - Dec 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1705140554/funding/production/1._Spurs_Lane_-_Cost_to_Complete_Report_-_12.18.23_gx9p8y.pdf",
        },
        {
          title: "Schedule Report - Dec 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1705140665/funding/production/3b._Spurs_Lane_-_Detailed_Schedule_-_12.20.23_mcoye8.pdf",
        },
        {
          title: "Condensed Schedule Report - Dec 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1705140778/funding/production/3a._Spurs_Lane_-_Condensed_Schedule_12.20.23_xcw5pk.pdf",
        },
        {
          title: "Contingency Report - Dec 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1705140860/funding/production/4._Spurs_-_SOV_Contingency_Reallocations_12.15.23_thwy7m.pdf",
        },
        {
          title: "Owner Change Order - Dec 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1705140924/funding/production/2._Spurs_Lane_-_Owner_Change_Order_Log_12.18.23_alslbp.pdf",
        },
        {
          title: "Draw 17 Report - Dec 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1705141257/funding/production/5._Spurs_Lane_-_Draw_17_-_Through_12.31.23_obsn9r.pdf",
        },
      ],
      finance: [
        {
          title: "Job Cost Journal Report - Dec 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1705140373/funding/production/Spurs_WIP_2023-11_i8rxzn.pdf",
        },
        {
          title: "Income Statement Report - Dec 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1705140416/funding/production/Spurs_IS_2023-11_smycer.pdf",
        },
        {
          title: "Balance Sheet Report - Dec 2023",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1705140463/funding/production/Spurs_BS_2023-11_gwmbtz.pdf",
        },
      ],
    },
    {
      active: false,
      title: "Jan 2024",
      images: [
        {
          title: "Cost Report - Jan 2024",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1707562499/funding/production/Spurs_Lane_-_Cost_Report_-_01.31.24-7-10_eqn1wo.pdf",
        },
        // {
        //   title: "Schedule Report - Jan 2024",
        //   url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1705140665/funding/production/3b._Spurs_Lane_-_Detailed_Schedule_-_12.20.23_mcoye8.pdf",
        // },
        {
          title: "Condensed Schedule Report - Jan 2024",
          url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1707562500/funding/production/Spurs_Lane_-Condensed_Schedule_-_01.31.24-2_v4fdkq.pdf",
        },
        // {
        //   title: "Contingency Report - Jan 2024",
        //   url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1705140860/funding/production/4._Spurs_-_SOV_Contingency_Reallocations_12.15.23_thwy7m.pdf",
        // },
        // {
        //   title: "Owner Change Order - Jan 2024",
        //   url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1705140924/funding/production/2._Spurs_Lane_-_Owner_Change_Order_Log_12.18.23_alslbp.pdf",
        // },
        // {
        //   title: "Draw 17 Report - Jan 2024",
        //   url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1705141257/funding/production/5._Spurs_Lane_-_Draw_17_-_Through_12.31.23_obsn9r.pdf",
        // },
      ],
      // finance: [
      //   {
      //     title: "Job Cost Journal Report - Jan 2024",
      //     url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1705140373/funding/production/Spurs_WIP_2023-11_i8rxzn.pdf",
      //   },
      //   {
      //     title: "Income Statement Report - Jan 2024",
      //     url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1705140416/funding/production/Spurs_IS_2023-11_smycer.pdf",
      //   },
      //   {
      //     title: "Balance Sheet Report - Jan 2024",
      //     url: "https://res.cloudinary.com/cloudpencils-media/image/upload/v1705140463/funding/production/Spurs_BS_2023-11_gwmbtz.pdf",
      //   },
      // ],
    },
  ];
  const [list, setList] = useState(data);

  const [tab, setTab] = useState("home");

  let userAgent = navigator.userAgent;

  const parser = new UAParser();
  parser.setUA(userAgent);
  const result = parser.getResult();
  const deviceType = (result.device && result.device.type) || "desktop";

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 30,
    },
  };

  return (
    <section>
      <div className="container">
        <div className="section-head">Monthly Reports</div>
        <div className="section-sub">
          {list.map((o, i) => (
            <div
              key={`sub-btn-${i}`}
              className={`sub-item ${o.active ? "active" : ""}`}
              onClick={() => {
                var xx = [];
                for (var x = 0; x < list.length; x++) {
                  if (i === x) {
                    xx.push({ ...list[x], active: true });
                  } else {
                    xx.push({ ...list[x], active: false });
                  }
                }

                setList(xx);
              }}
            >
              {o.title}
            </div>
          ))}
        </div>

        <div className="section-desc">
          {(() => {
            const images = list?.find((o) => o.active === true)?.images;
            const finance = list?.find((o) => o.active === true)?.finance;

            if (finance?.length > 0) {
              return (
                <Tabs
                  defaultActiveKey="home"
                  className="mb-0"
                  onSelect={(e) => setTab(e)}
                >
                  <Tab eventKey="home" title="Project Reports">
                    {images && tab === "home" && (
                      <Carousel
                        // partialVisbile
                        deviceType={deviceType}
                        itemClass="image-item"
                        responsive={responsive}
                      >
                        {images.map((o, i) => {
                          return (
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                border: "1px solid #ccc",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "5px",
                                boxShadow: "0 0 2px 1px #ccc",
                              }}
                              key={`item_row_${i}`}
                              role={"button"}
                              src={o.link}
                              alt={o.text}
                              onClick={() => window.open(o.url, "_blank")}
                            >
                              <div className="text-center">
                                <FaFilePdf color="red" size={40} />
                                <div className="w-100">{o.title}</div>
                              </div>
                            </div>
                          );
                        })}
                      </Carousel>
                    )}
                  </Tab>
                  <Tab eventKey="profile" title="Financial Reports">
                    {finance && tab === "profile" && (
                      <Carousel
                        // partialVisbile
                        deviceType={deviceType}
                        itemClass="image-item"
                        responsive={responsive}
                      >
                        {finance.map((o, i) => {
                          return (
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                border: "1px solid #ccc",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "5px",
                                boxShadow: "0 0 2px 1px #ccc",
                              }}
                              key={`item_row_${i}`}
                              role={"button"}
                              src={o.link}
                              alt={o.text}
                              onClick={() => window.open(o.url, "_blank")}
                            >
                              <div className="text-center">
                                <FaFilePdf color="red" size={40} />
                                <div className="w-100">{o.title}</div>
                              </div>
                            </div>
                          );
                        })}
                      </Carousel>
                    )}
                  </Tab>
                </Tabs>
              );
            } else {
              return (
                images && (
                  <Carousel
                    // partialVisbile
                    deviceType={deviceType}
                    itemClass="image-item"
                    responsive={responsive}
                  >
                    {images.map((o, i) => {
                      return (
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            border: "1px solid #ccc",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "5px",
                            boxShadow: "0 0 2px 1px #ccc",
                          }}
                          key={`item_row_${i}`}
                          role={"button"}
                          src={o.link}
                          alt={o.text}
                          onClick={() => window.open(o.url, "_blank")}
                        >
                          <div className="text-center">
                            <FaFilePdf color="red" size={40} />
                            <div className="w-100">{o.title}</div>
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                )
              );
            }
          })()}
        </div>
      </div>
    </section>
  );
};

export default MonthlyReports;
