import React, { useEffect } from "react";

import Home from "./pages/home";
import Properties from "./pages/properties";
import SingleProperty from "./pages/single-property";
import Invest from "./pages/invest";
import InvestingAccount from "./pages/investing-account";
import "./custom.scss";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";

import Profile from "./pages/profile";
import Register from "./pages/account/register";
import Login from "./pages/account/login";
import ForgotPassword from "./pages/account/forgot-password";
import NewOfferProperty from "./pages/new-offer-property";
import { Toaster } from "react-hot-toast";

import "./App.css";

import "./custom.scss";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutThunk } from "./redux/thunk/user.thunk";
import { getCookies } from "./utils/cookies";
import Logout from "./pages/logout";

let AuthContext = React.createContext(null);

function AuthProvider({ children }) {
  let [user, setUser] = React.useState(null);

  let signin = (newUser, callback) => {
    setUser(newUser);
    callback();
  };

  let signout = (callback) => {
    setUser(null);
    callback();
  };

  let value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

function App() {
  return (
    <>
      <div className="top-loader">Loading....</div>
      <div className="whole-content">
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              {/* <Route path="/" element={<Home />} /> */}
              {/* <Route path="/" element={<NewOfferProperty />} /> */}

              <Route path="/:page_id" element={<Home />} />
              <Route path="/single-property" element={<SingleProperty />} />
              {/* <Route path="/logout" element={<Logout />} />
             
              <Route
                path="/invest"
                element={
                  <PrivateRoute>
                    <Invest />
                  </PrivateRoute>
                }
              /> */}
              {/* <Route path="/new" element={<NewOfferProperty />} /> */}
              {/* <Route path="/signup" element={<Register />} />
              <Route path="/signin" element={<Login />} />
              <Route
                path="/profile"
                element={
                  <PrivateRoute>
                    <Profile />
                  </PrivateRoute>
                }
              />
              <Route
                path="/profile/change-password"
                element={
                  <PrivateRoute>
                    <Profile showChangePassword />
                  </PrivateRoute>
                }
              /> */}
              <Route path="*" element={<Navigate to="/spurs" replace />} />
              <Route path="/" element={<Navigate to="/spurs" replace />} />
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            fontSize: "13px",
            borderRadius: "3px",
          },
          duration: 5000,
        }}
      />
    </>
  );
}

const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch();
  let location = useLocation();

  const { pathname } = location;
  console.log("🚀 ~ file: App.js:111 ~ PrivateRoute ~ pathname", pathname);

  const user = useSelector((state) => state.user);

  const token = getCookies();

  useEffect(() => {
    if (!token) {
      dispatch(userLogoutThunk());
    }
  }, [dispatch, token]);

  if (user.login) {
    if (!user.data.is_reset_password) {
      return children;
    } else {
      if (pathname !== "/create-password")
        return <Navigate to={{ pathname: "/create-password" }} replace />;
      else return children;
    }
  } else {
    return (
      <Navigate
        to={{ pathname: "/signin" }}
        state={{ from: location }}
        replace
      />
    );
  }
};

export default App;
